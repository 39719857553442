import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import moment from "moment"
import {BackHome} from "../components/common/back_home";
import GooglePlayButton from "../components/common/google_play_button";
import AppleStoreButton from "../components/common/apple_store_button";
import Notification from "../components/common/notification";
import rehypeReact from "rehype-react"

const Center = ({ children }) => <div style={{textAlign: "center"}}>{children}</div>;

const renderAst = new rehypeReact({
  createElement: React.createElement,
  flagment: React.Fragment,

  // mdファイルの変換ロジック
  components: {
    center: Center,
    notification     : Notification,
    applestorebutton : AppleStoreButton,
    googleplaybutton : GooglePlayButton,
  },
}).Compiler


export default function News({ data, location }) {
  const article = data.markdownRemark
  
  const title = article.frontmatter.title;
  const description = article.frontmatter.description || article.excerpt;
  const modifiedDate = moment(article.frontmatter.date);

  const pagePath = `/news${article.fields.slug}`;

  return (
      <Layout>
      <Seo
        title={title}
        description={description}
        pagePath={pagePath}
      />

      <article className="container article-container">
        <header className="article-header">
          <h1 itemprop="headline">{title}</h1>
          <p class="article-header__day">
            <time itemprop="dateModified" datetime={modifiedDate.format("YYYY-MM-DD")}>
              {modifiedDate.format("YYYY年M月D日")}
            </time>
          </p>
        </header>

        <section itemProp="articleBody">
          {
            renderAst(article.htmlAst)
          }
        </section>

      </article>

      <BackHome />
    </Layout>
  )
}

export const pageQuery = graphql`
  query NewsById(
    $id: String!
  ) {
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      htmlAst
      fields {
        slug
      }
      frontmatter {
        title
        date
      }
    }
  }
`
