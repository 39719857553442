import * as React from "react";

const Notification = ({children}) => {
  return (
    <>
      <div class="notification-frame frame-red">
        <div class="frame-title caution">
          注意
        </div>
        {children}
      </div>
    </>
  )
}

export default Notification
