import {Link} from "gatsby";
import * as React from "react";

export const BackHome = () => {
  return (
    <Link to={"/"}>
      <p className={"text-center"}>
        <a href="/">
          ホームに戻る
        </a>
      </p>
    </Link>
  )
}
